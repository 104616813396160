import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { getPatientContentUrl } from '@/redux/profile/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function ShowIDs() {
  const dispatch = useDispatch()
  const recUrl = useSelector(getPatientContentUrl)

  const requestClose = () => {
    dispatch(setAlertTypeVisibility(alertTypes.NEED_TO_SHOW_ID, false))
  }

  return (
    <EazeDrawer open onRequestClose={requestClose}>
      <CloseModalIcon onClick={requestClose} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>🏛</Emoji>
        <Title>Have Your IDs Ready</Title>
        <Paragraph>
          Your driver must confirm your state ID and medical recommendation in order to complete your delivery.
        </Paragraph>
        <Button onClick={requestClose}>Okay, got it!</Button>

        {recUrl && (
          <LinkContainer>
            <Link href={recUrl} style={{ width: '100%' }} passHref>
              <LinkButton rel="noopener noreferrer" target="_blank">
                View my medical card
              </LinkButton>
            </Link>
          </LinkContainer>
        )}
      </Modal>
    </EazeDrawer>
  )
}
