import { useEffect, useState } from 'react'

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'
import { useDispatch } from 'react-redux'

import { setCookie } from '@/redux/cookies/actions'
import { Button } from '@/stories/buttons/button'
import Drawer from '@/stories/drawer'
import { ButtonContainer, LinkContainer, Modal, Paragraph, Title } from '@/stories/modals/style'
import { breakpoint } from '@/theme'
import { track } from '@helpers/analytics'
import { FrownIcon, PeaceIcon } from '@microcomponents/icons/new'
import { LogoShape } from '@microcomponents/shapes/logo'

import { TEST_IDS } from '../test/constants'

export default function AgeGate() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleConfirm = () => {
    dispatch(setCookie({ hasVerifiedAge: true }))
    track('Modal.AgeGate.Confirm')
  }

  const handleDismiss = () => {
    track('Modal.AgeGate.Dismiss')
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true)
      track('Modal.AgeGate.View')
    }, 2000)
    // cleanup on unmount
    return () => clearTimeout(timer)
  }, [])

  return (
    <Drawer
      allowClose={false}
      modalElementClass={drawerCss}
      onRequestClose={() => {
        // Do Nothing (Comment needed to silence linting)
      }}
      open={open}>
      <StyledModal data-e2eid={TEST_IDS.COMPONENT}>
        <div>
          <LogoShape />
          <Title>{`Are you 21 years or older?`}</Title>
          <SlimParagraph>{`We need this information for legal stuff.`}</SlimParagraph>
          <ButtonContainer>
            <Button
              variant="variant-2"
              backgroundColor={theme.colors.button}
              icon={<PeaceIcon />}
              onClick={handleConfirm}
              data-e2eid={TEST_IDS.VERIFICATION_BUTTON}
              width="100%">
              Yes
            </Button>
          </ButtonContainer>
          <LinkContainer>
            <Link
              data-e2eid={TEST_IDS.GOOGLE_LINK}
              onClick={handleDismiss}
              href="https://google.com"
              rel="noopener noreferrer">
              <Button variant="variant-2" icon={<FrownIcon />} width="100%">
                No
              </Button>
            </Link>
          </LinkContainer>
        </div>
      </StyledModal>
    </Drawer>
  )
}

const drawerCss = css`
  text-align: center;

  @media (max-width: ${breakpoint.max.lg}px) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

const StyledModal = styled(Modal)`
  max-width: none;
  @media (max-width: ${breakpoint.max.lg}px) {
    border-radius: 0;
  }
`

const SlimParagraph = styled(Paragraph)`
  max-width: 250px;
  margin: auto;
`
