import { useState } from 'react'

import styled from '@emotion/styled'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'

import { setDismissedAnnouncements } from '@/redux/announcements/actions'
import { getNewestAnnouncement } from '@/redux/announcements/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { Message, Modal, Paragraph, Title } from '@/stories/modals/style'
import { isMobile } from '@helpers/constants'

import { TEST_IDS } from './test/constants'

/*
 * Not sure if this is even used in production
 * When used in staging, the attached img is not reachable so using state to hide container and only show if it loads
 */
export default function Announcement() {
  const dispatch = useDispatch()
  const [imgLoads, setImgLoads] = useState(false)
  const newestAnnouncement = useSelector(getNewestAnnouncement)

  const handleCloseRequest = () => {
    dispatch(setDismissedAnnouncements(newestAnnouncement.id))
  }

  return (
    <EazeDrawer open onRequestClose={handleCloseRequest}>
      <Modal data-e2eid={TEST_IDS.COMPONENT}>
        {imgLoads && (
          <ImageContainer>
            <Image
              src={isMobile ? `${newestAnnouncement.photoUrl}?preset=thumb` : newestAnnouncement.photoUrl}
              layout="fill"
              onError={() => setImgLoads(false)}
              onLoad={() => setImgLoads(true)}
            />
          </ImageContainer>
        )}
        <Message>
          <Title>{newestAnnouncement.name}</Title>
          <Paragraph>{newestAnnouncement.description}</Paragraph>
        </Message>
        <Button variant="variant-9" onClick={handleCloseRequest} data-e2eid={TEST_IDS.DISMISS_BUTTON}>
          Continue Browsing
        </Button>
      </Modal>
    </EazeDrawer>
  )
}

const ImageContainer = styled.div`
  height: 23rem;
  overflow: hidden;
`
