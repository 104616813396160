import window from 'global/window'
import { Router } from 'next/router'
import { ActionCreator } from 'redux'

import { DEFAULT_MENU_SLUG, EAZE_ORIGIN } from '@helpers/constants'
import { modifyURL } from '@helpers/path'
import { track } from 'analytics'
import api from 'helpers/api'
import errorHandler from 'helpers/error-handler'
import { CASH_PAYMENT_METHOD, ACH_PAYMENT_METHOD } from 'helpers/payment'
import { quoteLoaded, quoteLoading } from 'redux/loading/actions'
import { getActivePaymentId, isCashOnly } from 'redux/payments/selectors'
import { openApplyPromo, receiveApplyPromo, requestApplyPromo, resetPromo, setPromo } from 'redux/promo/actions'

import { rehydrateCookies } from '../cookies/actions'

const PROMO_KEY = 'promo_code'

export function sendApplyPromo(): ActionCreator<void> {
  return (dispatch, getState) => {
    const state = getState()
    const { cart, promo, user, cookies } = state
    const userId = user.userId || cookies.userId
    const code = (promo.code || '').trim()
    const id = cart[DEFAULT_MENU_SLUG].id || cookies.cartId

    if (code === '') return dispatch(resetPromo())

    // Show non-rounded totals for quotes call
    let paymentMethod = ACH_PAYMENT_METHOD
    if (isCashOnly || getActivePaymentId(state) === CASH_PAYMENT_METHOD) {
      paymentMethod = CASH_PAYMENT_METHOD
    } else if (getActivePaymentId(state)) {
      paymentMethod = getActivePaymentId(state)
    }

    if (!userId) {
      dispatch(resetPromo())
      return dispatch(receiveApplyPromo({ success: false, error: 'Please log in' }))
    }

    dispatch(quoteLoading())
    dispatch(requestApplyPromo())

    return api.requestQuote(
      { promoCode: code, cartId: id, origin: EAZE_ORIGIN, paymentMethod },
      (err, res: { priceInfo: { promoCredit: number; inviteCredit: number } }) => {
        let payload = {}
        dispatch(quoteLoaded())
        if (err) {
          dispatch(deletePromo())
          payload = {
            ...payload,
            error: err.message,
            success: false
          }
          track('Modal.PromoApply.Error', { error: err.message })
          errorHandler(new Error(err.message))
        }

        if (res) {
          dispatch(savePromo(code))
          window && window.sessionStorage.setItem(PROMO_KEY, code)
          payload = {
            ...payload,
            amount: res.priceInfo.promoCredit + res.priceInfo.inviteCredit,
            success: true,
            error: false
          }
        }
        dispatch(receiveApplyPromo(payload))
      }
    )
  }
}

export function deletePromo(): ActionCreator<void> {
  return (dispatch) => {
    window && window.sessionStorage.removeItem(PROMO_KEY)
    dispatch(resetPromo())
  }
}

export function getPromo(): ActionCreator<void> {
  return (dispatch) => {
    if (window && window.sessionStorage.getItem(PROMO_KEY)) {
      dispatch(setPromo(window.sessionStorage.getItem(PROMO_KEY)))
      rehydrateCookies(dispatch)
      dispatch(sendApplyPromo())
    }
  }
}

function savePromo(code: string): ActionCreator<void> {
  return (dispatch) => {
    window && window.sessionStorage.setItem(PROMO_KEY, code.toUpperCase())
    dispatch(setPromo(code))
  }
}

export function openPromoModal(router: Router): ActionCreator<void> {
  return (dispatch) => {
    track('Modal.PromoApply.View')

    router.push(modifyURL(router, { applyPromo: 'true', cart: null }), undefined, { shallow: true })

    dispatch(openApplyPromo())
  }
}
