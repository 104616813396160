/**
 * Upload error modal component used for state ID and MMID upload
 * Shows a loading spinner and a message
 */
import { useDispatch, useSelector } from 'react-redux'

import { setAlertTypeVisibility } from '@/redux/alert/actions'
import { alertTypes } from '@/redux/alert/config/types'
import { getVerificationProcessErrors } from '@/redux/verification/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from '@/stories/modals/style'

export default function UploadError() {
  const dispatch = useDispatch()
  const verificationProcessErrors = useSelector(getVerificationProcessErrors)

  const onClick = () => {
    dispatch(setAlertTypeVisibility(alertTypes.IMAGE_UPLOAD_ERROR, false))
  }

  return (
    <EazeDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>😳</Emoji>
        <Title>Oops!</Title>
        <Paragraph>{verificationProcessErrors}</Paragraph>

        <Button onClick={onClick}>OK</Button>
      </Modal>
    </EazeDrawer>
  )
}
